<template>
<div id="app">

    <v-row>

        <v-col cols="5" lg="2">
            <v-select :items="list_type_recherche" v-model="selecttype_recherche" dense outlined label="Type de recherche">

            </v-select>
        </v-col>
        <v-col lg="2" cols="5">
            <v-text-field v-model="libelle_recherche" label="Recherche..." dense outlined></v-text-field>

        </v-col>
        <v-col>
            <v-btn color="primary" dark class="mb-2" @click="readAll">
                Recherche
            </v-btn>

        </v-col>

        <v-col v-if="null">
            <v-btn color="green" dark class="mb-2" @click="Actulisation()">
                Actualiser
            </v-btn>

        </v-col>

    </v-row>

    <v-data-table :headers="headers" :items="list" sort-by="code" class="elevation-1">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>LISTE DES PARTENAIRES</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>

                <template>
                    <v-btn color="primary" dark class="mb-2" @click="nouveau()">
                        <v-icon dark>mdi-plus</v-icon> Nouveau
                    </v-btn>

                </template>

                <v-dialog v-model="dialog" max-width="850px">
                    <v-card>
                        <v-card-title class="text-h5">Formulaire de Compte</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" lg="6">
                                    <v-text-field v-model="detail_Formulaire.noms" label="Nom" outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <v-text-field v-model="detail_Formulaire.prenoms" label="Prenom" outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <v-text-field v-model="detail_Formulaire.email" label="E-mail" outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <v-text-field v-model="detail_Formulaire.telephone" label="Telephone" outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <v-text-field v-model="detail_Formulaire.telephone_whatsapp" label="Numero Whatsapp" outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <v-text-field v-model="detail_Formulaire.garentie" label="Garentie" outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <v-text-field v-model="detail_Formulaire.reserve" label="Reserve" outlined></v-text-field>
                                </v-col>

                                <v-col cols="12" lg="6">
                                    <v-select :items="list_pays" v-model="detail_Formulaire.pays" item-value="id" outlined label="Type de pays">
                                        <template slot="selection" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.libelle }}
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <v-select :items="list_type_carte" v-model="detail_Formulaire.type_cartes" outlined label="Type de carte">

                                    </v-select>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <v-text-field v-model="detail_Formulaire.reference_carte" label="Reference de la carte" outlined></v-text-field>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <v-file-input accept="image/*" v-model="detail_Formulaire.carte" label="Photo carte" outlined></v-file-input>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <v-file-input accept="image/*" v-model="detail_Formulaire.photo" label="Photo identité" outlined></v-file-input>
                                </v-col>
                                <v-col cols="12" lg="6">
                                    <v-file-input accept="image/*" v-model="detail_Formulaire.doc_adresse" label="Document Adresse" outlined></v-file-input>
                                </v-col>

                            </v-row>

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog=false">Annuller</v-btn>
                            <v-btn color="blue darken-1" text @click="save()">Confirmer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-toolbar>
        </template>

        <template v-slot:[`item.status`]="{ item }">
            <v-switch v-model="item.status" :color="item.status==1?'green':'red'" :label="item.status==1?'Activer':'Desactiver'" inset @change="Action_status(item)">

            </v-switch>

        </template>

        <template v-slot:[`item.photo`]="{ item }">
            <v-btn icon @click="detail(item)">
                <v-icon color="green" large class="mr-2">
                    mdi-file-download
                </v-icon>
            </v-btn>
        </template>
        <template v-slot:[`item.carte`]="{ item }">
            <v-btn icon @click="detail(item)">
                <v-icon color="green" large class="mr-2">
                    mdi-file-download
                </v-icon>
            </v-btn>
        </template>
        <template v-slot:[`item.doc_adresse`]="{ item }">
            <v-btn icon @click="detail(item)">
                <v-icon color="green" large class="mr-2">
                    mdi-file-download
                </v-icon>
            </v-btn>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="detail(item)">
                <v-icon color="red" large class="mr-2">
                    mdi-delete
                </v-icon>
            </v-btn>
            <v-btn icon @click="Edition(item)">
                <v-icon color="blue" large class="mr-2">
                    mdi-pencil
                </v-icon>
            </v-btn>

        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="Actulisation">
                Actualiser la liste
            </v-btn>
        </template>
    </v-data-table>

</div>
</template>

<script>
//import api from '../../../serviceApi/apiService'
import api from '@/serviceApi/apiService'

export default {
    name: "Gestion des clients",
    data: () => ({
        dialog: false,
        dialog2: false,
        search: "",
        detail_Formulaire: "",
        headers: [{
                text: "Actions",
                align: "start",
                sortable: false,
                value: "actions",
                divider: true,
                width: '150px'

            },
            {
                text: "status",
                value: "status",
                divider: true,
                width: '50px'
            },

            {
                text: "CODE PARTENAIRE",
                align: "start",
                sortable: false,
                value: "code_partenaire",
                divider: true,
                width: '150px'
            },
            {
                text: "NOM",
                align: "start",
                sortable: false,
                value: "noms",
                divider: true,
                width: '150px'
            },
            {
                text: "PRENOMS",
                align: "start",
                sortable: false,
                value: "prenoms",
                divider: true,
                width: '150px'
            },

            {
                text: "E-MAIL",
                align: "start",
                sortable: false,
                value: "email",
                divider: true,
                width: '100px'
            },
            {
                text: "Telephone",
                align: "start",
                sortable: false,
                value: "telephone",
                divider: true,
                width: '80px'
            },
            {
                text: "Telephone Whatsapp",
                align: "start",
                sortable: false,
                value: "telephone_whatsapp",
                divider: true,
                width: '80px'
            },
            {
                text: "Garentie",
                align: "start",
                sortable: false,
                value: "garentie",
                divider: true,
                width: '80px'
            },
            {
                text: "Reserve",
                align: "start",
                sortable: false,
                value: "reserve",
                divider: true,
                width: '80px'
            },
            {
                text: "Pays",
                align: "start",
                sortable: false,
                value: "pays",
                divider: true,
                width: '80px'
            },
            {
                text: "TYPE DE CARTE",
                align: "start",
                sortable: false,
                value: "type_cartes",
                divider: true,
                width: '80px'
            },

            {
                text: "REFERENCE DE LA CARTE",
                align: "start",
                sortable: false,
                value: "reference_carte",
                divider: true,
                width: '80px'
            },
            {
                text: "PHOTO IDENTITE",
                align: "start",
                sortable: false,
                value: "photo",
                divider: true,
                width: '80px'
            },

            {
                text: "CARTE",
                align: "start",
                sortable: false,
                value: "carte",
                divider: true,
                width: '80px'
            },

            {
                text: "Document Adresse",
                align: "start",
                sortable: false,
                value: "doc_adresse",
                divider: true,
                width: '80px'
            },

        ],

        commentaire: '',
        selectstatus: '',
        detail_Formulaire: {
            id: '',
            code_partenaire: '',
            noms: '',
            prenoms: '',
            email: '',
            garentie: '',
            reserve: '',
            photo: '',
            telephone: '',
            telephone_whatsapp: '',
            type_cartes: '',
            reference_carte: '',
            carte: '',
            doc_adresse: '',
            status: '',
            pays: '',
        },

        list: [],
        list_profils: [],
        selectprofil: 0,
        list_type_recherche: ['CODE CLIENT', 'E-MAIL'],
        valeur: '',
        etat: false,
        selecttype_recherche: '',
        libelle_recherche: '',
        list_status: ['Valider', 'Bloquer', 'En attente'],
        list_pays: [],
        list_type_carte: ['PASSEPORT', 'CIN', 'PERMIS DE CONDUIRE'],

    }),

    created() {
        this.readAll_pays();
        this.readAll();

    },
    methods: {

        readAll: async function () {
            let fd = new FormData();
            const data = await api.createUpdatedata('backoffice/all-partenaire', fd);
            this.list = data;

        },
        readAll_pays: async function () {
            let fd = new FormData();

            const data = await api.createUpdatedata('backoffice/all-pays', fd);
            this.list_pays = data;

        },

        detail: async function (item) {
            this.detail_Formulaire.nom_prenom = item.nom_prenom;
            this.detail_Formulaire.email = item.email;
            this.detail_Formulaire.id = item.id;
            this.detail_Formulaire.profil = item.profils.id;
            this.etat = true;
            this.dialog = true;
        },

        save: async function () {

            let fd = new FormData();
            fd.append('noms', this.detail_Formulaire.noms);
            fd.append('prenoms', this.detail_Formulaire.prenoms);
            fd.append('email', this.detail_Formulaire.email);
            fd.append('telephone', this.detail_Formulaire.telephone);
            fd.append('telephone_whatsapp', this.detail_Formulaire.telephone_whatsapp);
            fd.append('pays', this.detail_Formulaire.pays);
            fd.append('photo', this.detail_Formulaire.photo);
            fd.append('doc_adresse', this.detail_Formulaire.doc_adresse);
            fd.append('carte', this.detail_Formulaire.carte);
            fd.append('reference_carte', this.detail_Formulaire.reference_carte);
            fd.append('type_cartes', this.detail_Formulaire.type_cartes);
            fd.append('reserve', this.detail_Formulaire.reserve);
            fd.append('garentie', this.detail_Formulaire.garentie);

            if (this.etat == false) {
                const data = await api.createUpdatedata('backoffice/add-partenaire', fd);

                if (data.status == true) {
                    this.showAlert(data.message, 'success');
                    this.dialog = false;
                    this.readAll();
                    this.clearItem();
                } else {
                    this.clearItem();
                    this.showAlert(data.message, 'error');
                }
            } else {
                const data = await api.createUpdatedata('backoffice/update-partenaire/' + this.detail_Formulaire.id, fd);

                if (data.status == true) {
                    this.showAlert(data.message, 'success');
                    this.dialog = false;
                    this.readAll();
                    this.clearItem();
                } else {
                    this.showAlert(data.message, 'error');
                }
            }

        },
        nouveau: async function () {
            this.clearItem();
            this.dialog = true;
            this.etat = false;
        },

        Edition(item) {
            this.detail_Formulaire.id = item.id;
            this.detail_Formulaire.code_partenaire = item.code_partenaire;
            this.detail_Formulaire.noms = item.noms;
            this.detail_Formulaire.prenoms = item.prenoms;
            this.detail_Formulaire.email = item.email;
            this.detail_Formulaire.garentie = item.garentie;
            this.detail_Formulaire.reserve = item.reserve;
            //this.detail_Formulaire.photo = item.photo;
            this.detail_Formulaire.telephone = item.telephone;
            this.detail_Formulaire.telephone_whatsapp = item.telephone_whatsapp;
            this.detail_Formulaire.type_cartes = item.type_cartes;
            this.detail_Formulaire.reference_carte = item.reference_carte;
            //this.detail_Formulaire.carte = item.carte;
            //this.detail_Formulaire.doc_adresse = item.doc_adresse;
            // this.detail_Formulaire.status = item.status;
            this.detail_Formulaire.pays = item.pays;
            this.etat = true;
            this.dialog = true;
        },

        clearItem() {

            this.detail_Formulaire = {
                id: '',
                code_partenaire: '',
                noms: '',
                prenoms: '',
                email: '',
                garentie: '',
                reserve: '',
                photo: '',
                telephone: '',
                telephone_whatsapp: '',
                type_cartes: '',
                reference_carte: '',
                carte: '',
                doc_adresse: '',
                status: '',
                pays: '',
            };

        },

        async Action_status(item) {
            const data = await api.readAll('backoffice/etat-partenaire/' + item.id);
            if (data.status == true) {
                this.showAlert(data.message, 'success');
            } else {
                this.showAlert(data.message, 'error');
            }
        },

        showAlert(textmessage, txticone) {
            this.$swal.fire({
                position: 'top-end',
                icon: txticone,
                title: textmessage,
                showConfirmButton: false,
                timer: 1500
            })
        },

        fetchData() {
            this.readAll();
        },

    },
};
</script>
